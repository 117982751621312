import React from "react";

export default class ResultsCard extends React.Component {
  render() {
    const circleCommonClasses = "h-2 w-2 bg-current rounded-full";
    return (
      <div className={`${this.props.className}`}>
        <div
          className={`${circleCommonClasses} mr-1 animate-bounce inline-block`}
        ></div>
        <div
          className={`${circleCommonClasses} mr-1 animate-bounce200 inline-block`}
        ></div>
        <div
          className={`${circleCommonClasses} animate-bounce400 inline-block`}
        ></div>
      </div>
    );
  }
}
