import React from "react";
import { parseUrl } from "query-string";
import firebase from "firebase/app";
import "firebase/firestore";
import ResultsCard from "../Components/ResultsCard.js";
import LoadingBalls from "../Components/LoadingBalls.js";

export default class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      query: undefined,
      loading: false,
    };
  }
  async componentDidMount() {
    //Set loading
    this.setState({ loading: true });

    //Parse URL
    const { query } = parseUrl(this.props.location.search);
    this.setState({ query });

    //Get the weather for the starting location
    const { addressLat, addressLong } = query;
    const weather = await getWeather(addressLat, addressLong, false);

    //Get valid destination cities
    const db = firebase.firestore();
    let citiesQuery = db.collection("cities");

    //Add landform filters to query
    if (JSON.parse(query.Mountains))
      citiesQuery = citiesQuery.where(`Landforms.Mountain`, "==", true);
    if (JSON.parse(query.Forest))
      citiesQuery = citiesQuery.where(`Landforms.Forest`, "==", true);
    if (JSON.parse(query.Desert))
      citiesQuery = citiesQuery.where(`Landforms.Desert`, "==", true);
    if (JSON.parse(query.Oceans))
      citiesQuery = citiesQuery.where(`Landforms.Ocean`, "==", true);

    //Add temperature filter to query
    if (JSON.parse(query.Warmer) ^ JSON.parse(query.Cooler)) {
      if (JSON.parse(query.Warmer)) {
        citiesQuery = citiesQuery.where("Temperature", ">=", weather.temp);
      } else {
        citiesQuery = citiesQuery.where("Temperature", "<", weather.temp);
      }
    }

    //Execute query (Can't include cloudCoverage due to firebase limitations)
    let result = (await citiesQuery.get()).docs.map((d) => d.data());

    //Remove current city from potenial cities
    result = result.filter((c) => c.AirportCode !== query.startingCityCode);

    //Filter out cities that don't meet cloud coverage requirement
    if (JSON.parse(query.Sunnier) ^ JSON.parse(query.Rainier)) {
      if (JSON.parse(query.Sunnier)) {
        result = result.filter((c) => c.CloudCoverage >= weather.clouds);
      } else {
        result = result.filter((c) => c.CloudCoverage < weather.clouds);
      }
    }

    this.setState({ cities: result, loading: false });
  }

  render() {
    return (
      <div className="text-center">
        <div className="m-8">
          <div className="text-5xl font-bold">
            These cities match your preferences
          </div>
          <div className="font-bold text-secondary mt-2">
            Choose one to visit
          </div>
        </div>

        <div className="mx-auto md:w-3/4 max-w-4xl grid grid-cols-1 gap-6">
          {this.state.cities &&
          this.state.cities.length &&
          !this.state.loading ? (
            this.state.cities.map((city) => (
              <ResultsCard
                key={city.Name}
                city={city}
                origin={this.state.query.startingCityCode}
                departureDate={this.state.query.departureDate}
                returnDate={this.state.query.returnDate}
                history={this.props.history}
              />
            ))
          ) : this.state.loading ? (
            <div className="text-primary text-xl">
              <div className="inline-block">Finding Your Destinations</div>
              <LoadingBalls className="inline-block ml-2" />
            </div>
          ) : (
            <div className="text-primary text-xl">
              <div className="inline-block">
                Unfortunately, there are no cities that match your search.{" "}
                <a className="underline" href="/">
                  Change your search
                </a>{" "}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

async function getWeather(lat, lon, celciusBool) {
  let unitURIChar = celciusBool ? "M" : "I";

  const apiKey = "6942a109d16b4ee4bf68c9b3ea59e4ce";
  var uri = `https://api.weatherbit.io/v2.0/current?&lat=${lat}&lon=${lon}&units=${unitURIChar}&key=${apiKey}`;

  const respJSON = (await (await fetch(uri)).json()).data[0];

  return respJSON;
}
