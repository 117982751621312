import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import firebase from "firebase/app";

var config = {
  apiKey: "AIzaSyDUMvhRWDH6_muQATtVAp_sx-u-7_0M4yQ",
  authDomain: "travelengine-3e5e4.firebaseapp.com",
  databaseURL: "https://travelengine-3e5e4.firebaseio.com",
  projectId: "travelengine-3e5e4",
  storageBucket: "travelengine-3e5e4.appspot.com",
  messagingSenderId: "885539042202",
  appId: "1:885539042202:web:a5b970dac57fefec",
};

firebase.initializeApp(config);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
