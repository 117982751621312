import React from "react";
import HomeScreen from "./Screens/home";
import ResultsScreen from "./Screens/results";
import CityDetails from "./Screens/cityDetails";
import { BrowserRouter as Router, Route } from "react-router-dom";

export default class App extends React.Component {
  render() {
    return (
      <div className="text-primary">
        <Router>
          <Route path="/" exact component={HomeScreen} />
          <Route path="/results/" component={ResultsScreen} />
          <Route path="/cityDetails" component={CityDetails} />
        </Router>
      </div>
    );
  }
}
