import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Amadeus from "amadeus";
import Autocomplete from "react-google-autocomplete";
import LoadingBalls from "../Components/LoadingBalls.js";

export default class HomeScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Warmer: false,
      Cooler: false,
      Oceans: false,
      Mountains: false,
      Forest: false,
      Desert: false,
      Rainier: false,
      Sunnier: false,
      startingCityText: "",
      startingCityCode: undefined,
      addressLat: undefined,
      addressLong: undefined,
      departureDate: undefined,
      returnDate: undefined,
      loading: false,
    };

    this.locationHandler = this.locationHandler.bind(this);
    this.getResults = this.getResults.bind(this);
  }

  async getResults(thisWeekend) {
    //Set loading to true
    this.setState({ loading: true });

    // Determine starting and ending dates
    function addDays(date, days) {
      let d = new Date(date);
      d.setDate(d.getDate() + days);
      return d;
    }
    const today = new Date();

    const targetDepart = 5; //Friday is day 5
    const duration = 2; //Stay for a 2 day weekend
    const currDayNum = today.getDay();

    let nextTarget;

    if (currDayNum > targetDepart) {
      nextTarget = addDays(today, 7 - (currDayNum - targetDepart));
    } else if (currDayNum < targetDepart) {
      nextTarget = addDays(today, targetDepart - currDayNum);
    } else if (currDayNum === targetDepart) {
      nextTarget = today;
    }

    const departureDate = thisWeekend ? nextTarget : addDays(nextTarget, 7);
    const returnDate = addDays(departureDate, duration);

    function formatDate(date) {
      const m = moment(date);
      return m.format("DD/MM/YYYY");
    }

    this.state.departureDate = formatDate(departureDate);
    this.state.returnDate = formatDate(returnDate);

    let amadeus = new Amadeus({
      clientId: "AwAQ0s8LOAGOXA2qxZJqH0PXNugSlmWz",
      clientSecret: "Snp5VTphVgPNgjRr",
    });

    const airports = await amadeus.referenceData.locations.airports.get({
      latitude: this.state.addressLat,
      longitude: this.state.addressLong,
    });

    this.state.startingCityCode = airports.data[0].iataCode;

    //Set loading to false
    this.setState({ loading: false });

    //Navigate to the results page
    let queryString = "?";
    Object.keys(this.state).forEach((key) => {
      queryString += `${key}=${this.state[key]}&`;
    });
    this.props.history.push(`/results${queryString}`);
  }

  locationHandler(place) {
    this.setState({
      startingCityText: place.formatted_address,
      addressLat: place.geometry.location.lat(),
      addressLong: place.geometry.location.lng(),
    });
  }

  render() {
    return (
      <div>
        <div className="md:grid md:grid-cols-[1fr,1fr] lg:grid-cols-[6fr,4fr] xl:grid-cols-[2fr,1fr] h-screen">
          <div
            style={{
              backgroundImage:
                "url(https://firebasestorage.googleapis.com/v0/b/travelengine-3e5e4.appspot.com/o/4256096.jpg?alt=media&token=d8748175-7275-452a-a7de-94ad0c482782)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            className="text-center hidden md:block"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/travelengine-3e5e4.appspot.com/o/TravelEngine.svg?alt=media&token=4bde9621-9b9a-41fc-bfa4-9f606eede693"
              alt="Travel Engine"
              className="mx-auto mt-4"
            />
          </div>
          <div className="p-8">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/travelengine-3e5e4.appspot.com/o/TravelEngine_Black.svg?alt=media&token=0806a032-a3f0-42e4-beb2-6077477a594e"
              alt="Travel Engine"
              className="md:hidden"
            />
            <div className="mt-20 md:mt-24 text-5xl font-bold max-w-lg">
              Discover Your Next Vacation Spot
            </div>
            <div>
              <div className="text-lg font-bold text-secondary mt-12">
                Where are you starting?
              </div>
              <Autocomplete
                className="w-1/2 textbox focus:outline-none font-bold mt-2"
                apiKey="AIzaSyClmRxieSwZLfRPZGLfphj7ZntejEtPKN0"
                onPlaceSelected={this.locationHandler}
                onChange={() =>
                  this.setState({
                    startingCityText: "",
                    addressLat: "",
                    addressLong: "",
                  })
                }
              />
            </div>
            <div className="mt-8">
              <div className="text-lg font-bold text-secondary ">
                What are you looking for?
              </div>
              <div>
                <label className="container">
                  Warmer than your current location
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ Warmer: e.target.checked });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container">
                  Cooler than your current location
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ Cooler: e.target.checked });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="mt-4">
                <label className="container">
                  Sunnier than your current location
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ Sunnier: e.target.checked });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container">
                  Cloudier than your current location
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ Rainier: e.target.checked });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="mt-4">
                <label className="container">
                  Mountains
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ Mountains: e.target.checked });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container">
                  Forests
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ Forest: e.target.checked });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container">
                  Oceans
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ Oceans: e.target.checked });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="container">
                  Deserts
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ Desert: e.target.checked });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="mt-8">
              <div>
                <button
                  className="text-xl font-bold focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed"
                  onClick={() => this.getResults(true)}
                  disabled={this.state.loading || !this.state.startingCityText}
                >
                  <div className="inline-block"> Go This weekend </div>
                  {this.state.loading ? (
                    <LoadingBalls className="inline-block ml-2" />
                  ) : (
                    <FontAwesomeIcon
                      color="#666363"
                      icon={faArrowRight}
                      className="ml-2 mt-1"
                    />
                  )}
                </button>
              </div>
              <div>
                <button
                  className="text-sm focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed"
                  onClick={() => this.getResults(false)}
                  disabled={this.state.loading || !this.state.startingCityText}
                >
                  Next weekend{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
