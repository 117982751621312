import axios from "axios";

const gFlights = axios.create({
  baseURL: "https://api.skypicker.com",
  // baseURL: "https://tequila-api.kiwi.com/v2",
  // headers: {
  //   apiKey: "oY-ja0gjzdNz0bvebME2fgJZ5MO7Br18",
  // },
});

function search(args) {
  // return gFlights.get("/search", {

  return gFlights.get("/flights", {
    params: { partner: "peytongardipeetravelengine", ...args },
  });
}

function getCarriers() {
  return gFlights.get("/carriers");
}

export { search, getCarriers };
