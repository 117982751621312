import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import LoadingBalls from "../Components/LoadingBalls.js";
import { search } from "./../Common/tequila.js";

export default class ResultsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cheapest: undefined,
      comfort: undefined,
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const allFlights = await search({
      fly_from: this.props.origin,
      fly_to: this.props.city.AirportCode,
      curr: "USD",
      date_from: this.props.departureDate,
      date_to: this.props.departureDate,
      return_from: this.props.returnDate,
      return_to: this.props.returnDate,
      flight_type: "return",
      locale: "en",
      partner_market: "us",
    });

    let _cheapest = 10000000000;
    let _comfort = 0;

    let cheapest = undefined;
    let comfort = undefined;

    allFlights.data.data.forEach((flight) => {
      if (flight.price < _cheapest) {
        cheapest = flight;
        _cheapest = flight.price;
      }
      if (flight.quality > _comfort) {
        comfort = flight;
        _comfort = flight.quality;
      }
    });

    this.setState({
      cheapest: cheapest.price.toFixed(2),
      comfort: comfort.quality.toFixed(2),
      loading: false,
    });
  }

  landformTags(landforms) {
    const final = [];
    Object.keys(landforms).forEach((key) => {
      if (landforms[key]) final.push(key);
    });
    return final.map((landform) => (
      <div
        key={landform}
        className="inline-block mr-2 text-xs italic text-secondary"
      >
        {landform}
      </div>
    ));
  }

  render() {
    const city = this.props.city;
    return (
      <div
        key={city.Name}
        className="text-left border-t border-b sm:border-l md:border-r md:rounded-sm "
        style={{ borderColor: "#666363" }}
      >
        <div className="grid grid-cols-3 ">
          <div className="px-4 pt-4">
            <div className="text-xl font-bold">{city.Name}</div>
            <div className="text-sm font-bold text-secondary">{city.State}</div>
          </div>
          <div className="px-4 pt-4">
            <div className="text-xl font-bold">{city.Temperature} °F</div>
            <div className="text-sm font-bold text-secondary">
              {city.WeatherDescription}
            </div>
          </div>
          <div className="bg-gray-100 rounded-bl-md  px-4 pt-4 pb-1 grid sm:grid-cols-2">
            <div className="mr-4">
              {this.state.loading ? (
                <LoadingBalls />
              ) : (
                <div className="text-xl font-bold">${this.state.comfort}</div>
              )}

              <div className="text-sm font-bold text-secondary">
                Most Comfortable
              </div>
            </div>
            <div>
              {this.state.loading ? (
                <LoadingBalls />
              ) : (
                <div className="text-xl font-bold">${this.state.cheapest}</div>
              )}
              <div className="text-sm font-bold text-secondary">Cheapest</div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-16 mt-1 p-4">
          <div className="text-left">{this.landformTags(city.Landforms)}</div>
          <div className="text-right ">
            <button
              className="font-bold text-sm"
              onClick={() =>
                this.props.history.push(
                  `/cityDetails?currency=USD&fly_from=${this.props.origin}&fly_to=${city.AirportCode}&departure=${this.props.departureDate}&return=${this.props.returnDate}&destination=${city.Name}`
                )
              }
            >
              All Fights
              <FontAwesomeIcon
                color="#666363"
                icon={faArrowRight}
                className="ml-1 "
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
