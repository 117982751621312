import React from "react";
import "../App.css";
import { search, getCarriers } from "../Common/tequila.js";
import { parseUrl } from "query-string";
import FlightCard from "../Components/FlightCard.js";
import LoadingBalls from "../Components/LoadingBalls.js";

export default class CityDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carriers: undefined,
      flights: [],
      loading: false,
    };
  }
  async componentDidMount() {
    //Parse URL
    const { query } = parseUrl(this.props.location.search);
    this.setState({ loading: true });

    const flights = await search({
      fly_from: query.fly_from,
      fly_to: query.fly_to,
      curr: query.currency,
      date_from: query.departure,
      date_to: query.departure,
      return_from: query.return,
      return_to: query.return,
      flight_type: "return",
      locale: "en",
      partner_market: "us",
    });

    const carriers = (await getCarriers()).data;

    this.setState({
      flights: flights.data.data,
      carriers,
      loading: false,
    });
  }

  render() {
    return (
      <div className="text-center">
        <div className="m-8">
          <div className="text-5xl font-bold">
            These are the best flights for your trip
          </div>
          <div className="font-bold text-secondary mt-2">
            Pick the best one for you
          </div>
        </div>

        <div className="mx-auto md:w-3/4 max-w-4xl grid grid-cols-1 gap-6">
          {this.state.flights &&
          this.state.flights.length &&
          !this.state.loading ? (
            this.state.flights.map((flight) => (
              <FlightCard
                key={flight.deep_link}
                flight={flight}
                carriers={this.state.carriers}
              />
            ))
          ) : this.state.loading ? (
            <div className="text-primary text-xl">
              <div className="inline-block">Finding Your Flights</div>
              <LoadingBalls className="inline-block ml-2" />
            </div>
          ) : (
            <div className="text-primary text-xl">
              <div className="inline-block">
                Unfortunately, there are no available flights for your dates.{" "}
                <a className="underline" href="/">
                  Change your search
                </a>{" "}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
