import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default class ResultsCard extends React.Component {
  getDepartArriveTimes(flight, returnBool) {
    //Find the index of the first return flight
    let firstReturn;
    for (let i = 0; i < flight.route.length; i++) {
      if (flight.route[i].return) {
        firstReturn = i;
        break;
      }
    }

    const dIndex = returnBool ? firstReturn : 0;
    const aIndex = returnBool ? flight.route.length - 1 : firstReturn - 1;

    const d = moment(flight.route[dIndex].dTime * 1000)
      .add(7, "hours")
      .format("h:mma");
    const a = moment(flight.route[aIndex].aTime * 1000)
      .add(7, "hours")
      .format("h:mma");
    return <div>{`${d}  •  ${a}`}</div>;
  }

  getCarrierName(flight, returnBool) {
    let airlines = [];
    flight.route.forEach((r) => {
      if ((returnBool && r.return) || (!returnBool && !r.return))
        airlines.push(r.airline);
    });

    let carrierList = "";
    let count = 0;

    for (let i = 0; i < this.props.carriers.length; i++) {
      if (airlines.includes(this.props.carriers[i].id)) {
        carrierList += this.props.carriers[i].name;
        count++;
        if (count < airlines.length) carrierList += ", ";
      }
    }
    return <div>{carrierList}</div>;
  }

  getNumLayovers(flight, returnBool) {
    //Find the index of the first return flight
    let firstReturn;
    for (let i = 0; i < flight.route.length; i++) {
      if (flight.route[i].return) {
        firstReturn = i;
        break;
      }
    }

    const dIndex = returnBool ? firstReturn : 0;
    const aIndex = returnBool ? flight.route.length - 1 : firstReturn - 1;
    const numLayovers = aIndex - dIndex;
    const word = numLayovers === 1 ? "Stop" : "Stops";
    const phrase = numLayovers ? `${numLayovers} ${word}` : "nonstop";
    return <div>{phrase}</div>;
  }
  getLayoverInfo(flight, returnBool) {
    //Find the index of the first return flight
    let firstReturn;
    for (let i = 0; i < flight.route.length; i++) {
      if (flight.route[i].return) {
        firstReturn = i;
        break;
      }
    }

    const dIndex = returnBool ? firstReturn : 0;
    const aIndex = returnBool ? flight.route.length - 1 : firstReturn - 1;
    const numLayovers = aIndex - dIndex;
    let message = "Smooth sailing!";
    if (numLayovers) {
      message = "Stops in ";
      const flights = flight.route.slice(dIndex, aIndex + 1);
      flights.forEach((stop, index) => {
        if (index < flights.length - 1) message += `${stop.cityTo}`;
        if (index < flights.length - 2) message += `, `;
      });
    }
    return <div>{message}</div>;
  }

  render() {
    const flight = this.props.flight;

    return (
      <div className="text-left border-t border-b md:border-l md:border-r rounded-sm grid items-center border-[#666363] grid grid-cols-[5fr,1fr]">
        <div className="p-4">
          <div className="grid grid-cols-3 gap-8">
            <div>
              <div className="md:text-md lg:text-xl font-bold">
                {this.getDepartArriveTimes(flight, false)}
              </div>
              <div className="text-xs lg:text-sm font-bold text-secondary">
                {this.getCarrierName(flight, false)}
              </div>
            </div>
            <div>
              <div className="md:text-md lg:text-xl font-bold">
                {flight.fly_duration}
              </div>
              <div className="text-xs lg:text-sm font-bold text-secondary">
                {flight.flyFrom} - {flight.flyTo}
              </div>
            </div>
            <div>
              <div className="md:text-md lg:text-xl font-bold">
                {this.getNumLayovers(flight, false)}
              </div>
              <div className="text-xs lg:text-sm font-bold text-secondary">
                {this.getLayoverInfo(flight, false)}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-8 mt-4">
            <div>
              <div className="md:text-md lg:text-xl font-bold">
                {this.getDepartArriveTimes(flight, true)}
              </div>
              <div className="text-xs lg:text-sm font-bold text-secondary">
                {this.getCarrierName(flight, true)}
              </div>
            </div>
            <div>
              <div className="md:text-md lg:text-xl font-bold">
                {flight.return_duration}
              </div>
              <div className="text-xs lg:text-sm font-bold text-secondary">
                {flight.flyTo} - {flight.flyFrom}
              </div>
            </div>
            <div>
              <div className="md:text-md lg:text-xl font-bold">
                {this.getNumLayovers(flight, true)}
              </div>
              <div className="text-xs lg:text-sm font-bold text-secondary">
                {this.getLayoverInfo(flight, true)}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 h-full grid items-center">
          <div className="mx-auto">
            <div className="text-xl font-bold">${flight.price}</div>
            <div>
              <a
                className="font-bold text-sm"
                href={flight.deep_link}
                rel="noopener noreferrer"
                target="_blank"
              >
                Book Fights
                <FontAwesomeIcon
                  color="#666363"
                  icon={faArrowRight}
                  className="ml-1 "
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
